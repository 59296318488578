<template>
 
<div class="page">
    <div class="container">
        <h1>
            About
        </h1>
    </div>
  
</div>
 

</template>

<script>
export default {

}
</script>

<style scoped lang="scss">

</style>
